import request from '@/utils/request'

// client
export const initDevice = (data) => request.post('/client/init/register', data)
export const deviceLocalStatus = (data) => request.get('/check/master_review_status', data) // 跟local要
export const deviceRemoteStatus = (data) => request.post('/check/master_review_status', data) // 跟平台要
export const verifyDevice = (data) => request.post('/validate/validate_start_client_application', data)
export const syncMemberToDevice = (data) => request.post('/sync/membership/master_to_slave', data)
export const testIntegration = (data) => request.post('/transport/metadata/slave_to_master', data)
export const getIntegration = (data) => request.get('/listen/queue_record/', data)

// admin
export const getDeviceApplyList = (params) => request.get('/apply/distribute_device', { params })
export const updateDeviceStatus = (Id, data) => request.put(`/apply/distribute_device/${Id}`, data)
export const getDeviceQueueList = (params) => request.get('/client/listen/queue_records/', { params })
