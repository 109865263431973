<template>
  <div class="synthetic-container">
    <div class="synthetic-container-main">
      <div class="synthetic-bar">
        <span class="control-left">
          <span>{{ $t('Device.DeviceList') }}</span>
          <span class="control-text">
            <span>{{ formatToLocaleString(totalPage) }}</span>
          </span>
        </span>
      </div>
      <div class="synthetic-list">
        <DataList
          :list-loading="listLoading"
          :table-data="tableData"
        />
      </div>
    </div>
    <!-- pagination -->
    <div class="pagination-container">
      <el-pagination
        :background="true"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageLimit"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { UTCtoLocalTime } from '@/utils/handleTime'
import { FormatNumber } from '@/Mixins'
import DataList from './components/DataList.vue'
import { getDeviceQueueList } from '@/api/distribute'

const params = () => ({
  page: 1,
  page_limit: 20
})
export default {
  name: 'DeviceList',
  components: {
    DataList
  },
  mixins: [FormatNumber],
  data () {
    return {
      listLoading: false,
      params: params(),
      tableData: [],
      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
    }
  },
  mounted() {
    this.startInterval()
    this.fetchData()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      const res = await getDeviceQueueList(this.params)
      this.totalPage = res.data.page.total
      this.listLoading = false
      this.tableData = res.data.result.map((item) => {
        const newObj = {
          ...item,
          StatusStyle: 'bg-' + item.Status,
          StatusTooltip: this.$t(`Device.${item.Status}`),
          OrgName: item.organization_name,
          Untransport: item.Untransport,
          UpdatedTime: UTCtoLocalTime(item.Update_at),
          LoadingStatus: item.Transport_Ratio,
          RatioColor: this.formatRatioColor(item.Transport_Ratio)
        }
        return newObj
      })
    },
    startInterval() {
      const timer = setInterval(() => {
        this.fetchData()
      }, 5000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    // for pagination
    handleSizeChange(val) {
      this.params.page_limit = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.fetchData()
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    formatRatioColor(ratio) {
      let ratioColor = ''
      switch (true) {
        case ratio <= 60:
          ratioColor = '#95c11f'
          break
        case ratio > 60 && ratio < 100:
          ratioColor = '#ffb000'
          break
        case ratio >= 100:
          ratioColor = '#f56c6c'
          break
      }
      return ratioColor
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.synthetic-container {
  .synthetic-container-main {
    position: relative;
    flex-direction: column;
    .el-table .el-button {
      border: none;
      background-color: transparent;
    }
  }
}
.synthetic-bar {
  display:flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .control-left{
    text-align: left;
    .control-text{
      margin-left: 10px;
      font-size: 16px;
    }
  }
  .delete-button-container {
    display: flex;
    text-align: end;
    .delete-button {
      margin-left: 10px;
    }
  }
}
.synthetic-list {
  width: 100%;
  .el-table {
    .el-button{
      border: none;
      background-color: transparent;
    }
  }
  .el-table .el-button:hover {
    background-color: #F56C6C;
  }
}
/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
