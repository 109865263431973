<template>
  <div>
    <el-table
      v-loading="listLoading"
      stripe
      :data="tableData"
    >
      <el-table-column
        type="index"
        :label="$t('general.Index')"
        width="80"
      />
      <el-table-column 
        :label="$t('DeviceListField.Status')"
        width="80"
      >
        <template slot-scope="scope">
          <el-tooltip
            popper-class="status-tooltip"
            :content="scope.row.StatusTooltip"
            placement="right"
          >
            <el-button
              class="status-button"
            >
              <span :class="['point',scope.row.StatusStyle]" />
            </el-button>
          </el-tooltip>
          
        </template>
      </el-table-column>
      <el-table-column :label="$t('DeviceListField.OrgName')">
        <template slot-scope="scope">
          <el-tooltip
            popper-class="status-tooltip"
            :content="scope.row.uuid"
            placement="bottom-start"
          >
            <el-button
              class="status-button"
            >
              <div>{{ scope.row.OrgName }}</div>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="UpdatedTime"
        :label="$t(`DeviceListField.UpdatedTime`)"
      />
      <el-table-column :label="$t('DeviceListField.LoadingStatus')">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.LoadingStatus"
            popper-class="status-tooltip"
            :content="scope.row.Untransport"
            placement="bottom-start"
          >
            <el-button
              class="status-button"
            >
              <el-progress
                type="circle"
                :percentage="scope.row.LoadingStatus"
                :width="50"
                :color="scope.row.RatioColor"
              />
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <template slot="empty">
        <el-empty :description="$t('general.NoData')" />
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'DataList',
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.point {
  margin-right: 0.25rem;
  display: inline-block;
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 0.5rem;
}
.bg-online {
  background-color: $physiology_green;
}
.bg-error {
  background-color: $sport_yellow;
}
.bg-offline {
  background-color: $danger;
}
.bg-integration {
  background-color: $status_blue;
}
/* reset element-ui css */
.el-button.status-button{
  border: none;
  background-color: transparent;
  padding: 12px 5px;
}
// ::v-deep.status-tooltip.el-tooltip_popper.is-dark{
//     // background: $tooltips_bgc;
//     background: blue !important;
// }

.data-detail {
  .box-card{
    text-align: left;
  }
  .el-dialog__header{
    display: none;
  }
  .text {
    font-size: 14px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
}
</style>
